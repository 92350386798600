import { useMemo } from "react";

//
// Although Map has quite a few good properties,  note that there is no Map.map() or Map.values().map()
// which one might use when building a react node tree;  these functions return an actual list which you
// can apply map filter and all of that too
//

export function mapItems<A, B>(that: Map<A, B>): B[] {
  const there: B[] = [];
  that.forEach((item) => there.push(item));
  return there;
}

export function mapKeys<A,B>(that: Map<A, B>): A[] {
  const there: A[] = [];
  that.forEach((_, key) => there.push(key))
  return there;
}

export function mapMap<A,B,C>(that: Map<A,B>, fn: (a: A, b: B, index: number) => C): C[] {
  const there: C[] = []
  let i = 0
  that.forEach((v,k) => there.push(fn(k,v,i++)))
  return there
}

//
// convert array to set and memo it
//
export function useSet<A>(source: A[]) {
  return useMemo(() => new Set(source), [source]);
}