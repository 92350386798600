import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const networkStateSlice = createSlice({
    name:'appNetwork',
    initialState: {
        isApiDown:false,
    },
    reducers:{
        setNetworkState:(state, action:PayloadAction<boolean>) => {
            state.isApiDown = action.payload
        },
    }

})

export const { setNetworkState } = networkStateSlice.actions

export default networkStateSlice.reducer;