import { CircularProgress, Container, Stack } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useState } from "react";
import { uploadFiles } from '../../../Client/Files/files.client';
import { saveStudy } from '../../../Client/Study/study.client';
import { useID } from '../../../Hooks/Auth/useAuthStatus';
import { useAppDispatch } from '../../../Redux/Hooks/hooks.redux';
import { setNetworkState } from '../../../Redux/Reducer/network.state.reducer';
import { createNewSurvey } from '../../../Types/SurveyData/Utils/survey.utils';
import { AddInfo } from '../../../Types/SurveyData/add.info.type';
import { DetailedInfo } from '../../../Types/SurveyData/detailed.info.type';
import { SurveyData } from "../../../Types/SurveyData/survey.type";
import '../../../index.css';
import { Alert } from '../../Misc/Alert';
import { AdditionalInfoForm } from './add.info.form';
import { DetailedInformationForm } from './detailed.info.form';
import { FileForm } from './files.form';
import { SubmissionSuccessPage } from './submission.success.page';
import { SubmitPage } from './submit';
import { useCollectionModes } from "../../../Client/CollectionModes/collectionModes.client";
import { useSamplingProcedures } from "../../../Client/SamplingProcedures/samplingProcedures.client";

//
// How this form works:  this form consists of four subforms.  Note that the first two forms,  the
// DetailedInformationForm and the AdditionalInformationForm use react-hook-form to implement complex
// uncontrolled forms.  The other two forms are FileForm and SubmitPage and both of these are simple
// forms that don't use react-hook-form
//

export const SurveyForm: React.FC = () => {
    const id = useID();
    const collectionModes = useCollectionModes()
    const samplingProcedures = useSamplingProcedures()
    const [activePage, setActivePage] = useState<number>(0);
    const [formValues, setFormValues] = useState<SurveyData>(createNewSurvey());
    const pages = [
        "Required Info",
        "Additional Info",
        "Upload Files",
        "Submit"
    ]

    const dispatch = useAppDispatch()
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState([false, ""])
    const [success, setSuccess] = useState([false, ""])
    const moveUp = (index: number) => {
        setActivePage(index)
    }

    const setDetailedInfo = (d: DetailedInfo) => { setFormValues((prev) => { return { ...prev, detailedInfo: d } }) }
    const setAddInfo = (a: AddInfo) => { setFormValues((prev) => { return { ...prev, addInfo: a } }) }
    const setFiles = (f: File[]) => { setFormValues((prev) => { return { ...prev, files: f } }) }
    const setConfRisk = (b: "Y" | "N" | "DK") => { setFormValues((prev) => { return { ...prev, confRisk: b } }) }
    const setRiskDescription = (riskDescription: string) => {setFormValues((prev) => { return {...prev, riskDescription}})}
    const setNotes = (n: string) => { setFormValues((prev) => { return { ...prev, notes: n } }) }

    const safeGet = (data: SurveyData, fields: string[], def: any) => {
        let obj: any | null = data;
        for (const field of fields) {
            if (typeof obj === 'object' && field in obj) {
                obj = obj[field];
            } else {
                return def;
            }
        }
        return obj;
    };

    const reset = () => {
        setSuccess([false, ""]);
        setError([false, ""])
    }

    const callUploadFiles = (formValues: SurveyData, sid: string) => {
        console.assert(formValues.files.length > 0)
        var formData = new FormData();
        formValues.files.forEach((f: File) => formData.append('files', f, f.name))
        return uploadFiles(formData, sid)
    }

    const handleSubmit = () => {
        setProcessing(true)
        saveStudy(formValues, id).then(async (r) => {
            if (formValues.files.length > 0) return callUploadFiles(formValues, r.data.data.id!).then()
            return Promise.resolve(r)
        }
        ).then(() => {
            setActivePage(0);
            setFormValues(createNewSurvey())
            setSuccess([true, "Your study was uploaded successfully!"])
            setActivePage(4)
        }).catch(() => {
            setError([true, "An error occured."])
            dispatch(setNetworkState(true))
        }
        ).finally(() => setProcessing(false))
    }

    if (processing) return <Stack direction="column" sx={{ width: "100%", height: "100%" }} alignItems={"center"} justifyContent={"center"}>
        <CircularProgress></CircularProgress>
    </Stack>
    return (<Container>
        <Snackbar open={success[0] as boolean || error[0] as boolean} autoHideDuration={6000} onClose={() => reset()}>
            <Alert onClose={() => reset()} severity={error[0] ? "error" : "success"} sx={{ width: '100%' }}>
                {success[0] && success[1]}
                {error[0] && error[1]}
            </Alert>
        </Snackbar>
        <div style={{ padding: '10px 0px 30px 0px' }}>
            <Stepper activeStep={activePage}>
                {pages.map((label) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </div>
        {activePage === 0 &&
            <DetailedInformationForm
                surveyOrgs={safeGet(formValues, ["basicInfo", "surveyOrgs"], [])}
                surveySponsors={safeGet(formValues, ["basicInfo", "surveySponsors"], [])}
                setDetailedInfo={setDetailedInfo}
                moveUp={moveUp}
            />}
        {activePage === 1 && <AdditionalInfoForm
            title={formValues.detailedInfo.title}
            setAddInfo={setAddInfo}
            moveUp={moveUp}
            collectionModes={collectionModes}
            samplingProcedures={samplingProcedures}
        ></AdditionalInfoForm>}
        {activePage === 2 && <FileForm setFiles={setFiles} moveUp={moveUp} setConfRisk={setConfRisk}
                                       setRiskDescription={setRiskDescription}></FileForm>}
        {activePage === 3 && <SubmitPage setNotes={setNotes} handleSubmit={handleSubmit}></SubmitPage>}
        {activePage === 4 && <SubmissionSuccessPage/>}
    </Container>);
}

