import { createTheme } from '@mui/material/styles';
import { UI_CONSTANTS } from '../Constants/ui.constants';
import { UIMode } from '../State/UI/UI.state';
export function createAppTheme(mode: UIMode) {
    return createTheme({
        typography:
        {
            allVariants: {
                fontFamily: "Lato",
                color: mode === 'dark' ? "white" : "black"
            },

        },
        palette: {
            mode: mode,
            primary: {
                main: mode === "light" ? UI_CONSTANTS["Carnellian"] : UI_CONSTANTS["L Carnellian"],
                dark: mode === "light" ? UI_CONSTANTS["Dark Carnellian"] : UI_CONSTANTS["L Dark Carnellian"],
            },
        },
        components: {
            MuiContainer: {
                defaultProps: {
                    maxWidth: false
                },
                styleOverrides: {
                    root: {
                        paddingTop: '25px'
                    },
                },
            }
        }
    })
}
