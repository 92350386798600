import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Fab from '@mui/material/Fab';
import { useEffect } from 'react';
import { useUIStatus } from "../../Hooks/UI/useModeStatus";
import { useAppDispatch } from '../../Redux/Hooks/hooks.redux';
import { setUIMode } from '../../Redux/Reducer/app.state.reducer';
import { UIMode } from '../../State/UI/UI.state';
export function UIModeToggle() {


    const status = useUIStatus();
    const dispatch = useAppDispatch()
    const toggleStyle = {
        color: status === "light" ? "#cdcdcd" : "#1e1e1e",
        transition: "0.3s"
    }


    const setDocumentBackground = (mode: UIMode) => {
        document.documentElement.style.backgroundColor = mode === 'dark' ? "black" : "white";

    }

    useEffect(() => {
        setDocumentBackground(status)
    }, [status])

    function getComplement(status: UIMode) {
        if (status === "light") return "dark"
        return "light"
    }

    function handleUpdate() {
        const newMode = getComplement(status)
        dispatch(setUIMode(newMode));
    }


    return <Fab aria-label="ui-mode-toggle" size="small" onClick={handleUpdate} sx={toggleStyle}>{status === "light" ? <LightModeIcon /> : <DarkModeIcon />}</Fab>

}