import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { findEndpointById } from "../Endpoints/endpoints";
import { SERVICE_ID } from "../../Constants/ui.constants";
export type ReportingReponse = {
    id:string,
    providerId:string,
    date:Date,
    studyviews:number,
    questionviews:number,
    downloads:number

}
export type ReportingResponseWrapped = {data:{data: ReportingReponse[]}}
export async function fetchReporting(start:Date|null, end:Date|null, pid:string): Promise<ReportingResponseWrapped>{
    const today = new Date()
    if(!start) start = new Date(today.getFullYear(),0,1) //default to Jan 1 of this year
    if(!end) end = today //default to today
    const endpoint = findEndpointById("report")!
    var url = new URL(endpoint)   
    url.searchParams.append('start', start.toLocaleDateString())
    url.searchParams.append('end', end.toLocaleDateString())
    url.searchParams.append('providerId', pid)
    return axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    })
}

export const useReportingQuery = (start:Date|null, end:Date|null, pid:string) => {
    return useQuery({
      queryKey: ['filterReportingData', start, end],
      queryFn: () => fetchReporting(start, end, pid),
      staleTime: 24 * 60 * 60 * 1000, 
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false, 
    })
}