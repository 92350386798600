import { Nullable } from 'typescript-nullable';
import { ErrorPage } from '../Components/404/error.page';
import { LoginForm } from '../Components/Authentication/login.form';
import { BoxUpload } from '../Components/Box/box.page';
import { SurveyForm } from '../Components/DepositSurvey/Pages';
import { Home } from '../Components/Home/home';
import { ReportingPage } from '../Components/Reporting/Pages/reporting.page';
import { SubmissionPage } from '../Components/Submissions/Pages/submissions.page';
import { SurveyPage } from '../Components/Surveys/Pages/surveys.page';
export type Route = {
    name: string;
    id: string;
    component: Nullable<React.ComponentType<any>>;
    children: Nullable<Route[]>;
    isBase: boolean,
    isOnNav: boolean;
};
/**
 * Define nested routes here. The [unwrapRoutes] function will automagically
 * pull all base-level routes and set their id's and components appropriately.
 */
export const AppRoutes: Route[] = [
    {
        id: '', name: 'Root', component: Home, isBase: true, isOnNav: false,
        children: [
            {
                id: "/auth", name: "Authentication", component: null, isBase: false, isOnNav: false,
                children: [
                    { id: "/login", name: 'Login', component: LoginForm, isBase: false, isOnNav: false, children: null }
                ]
            },
            { id: "/form", name: "New Submission", component: SurveyForm, isBase: true, isOnNav: true, children: null },
            { id: "/submissions", name: "Submissions", component: SubmissionPage, isBase: true, isOnNav: true, children: null },
            { id: "/box", name: "Upload Files", component: BoxUpload, isBase: true, isOnNav: true, children: null },
            { id: "/surveys", name: 'Surveys', component: SurveyPage, isBase: true, isOnNav: true, children: null },
            { id: "/reporting", name: "Usage Reports", component: ReportingPage, isBase: true, isOnNav: true, children: null },
            { id: '/404', name: "Error", component: ErrorPage, isBase: true, isOnNav: false, children: null }
        ],
    },
]