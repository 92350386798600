import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppRouter } from './Components/Router/router';
import { useAppSelector } from './Redux/Hooks/hooks.redux';
import { AppRoutes } from './Routes/routes';
import { createAppTheme } from './Theme/AppTheme';




function App() {
  const mode = useAppSelector((state) => state.appState.appUIState.mode)
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={createAppTheme(mode)} >
          <AppRouter routes={AppRoutes}>
          </AppRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  )
}

export default App;
