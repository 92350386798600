import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { NavLink } from "react-router-dom";
import { MenuItem as MenuItemType, NestedMenuProps } from "./NestedMenuProps";

interface RenderMenuItemProps {
    item: MenuItemType;
    path: string;
}

const RenderMenuItem: React.FC<RenderMenuItemProps> = ({ item, path }) => {
    const itemPath = path ? `${path}/${item.id}` : item.id;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const itemKey = useMemo(() => `${path}-${item.id}`, [path, item.id]);

    if (!item.children) {
        return (
            <Box key={itemKey} sx={{ m: '0px 20px 0px 20px' }}>
                <NavLink to={itemPath} style={{ textDecoration: "none" }}>
                    <Typography data-testid="nested-menu-item" variant="subtitle1" color={"white"}>{item.name}</Typography>
                </NavLink>
            </Box>
        );
    }

    return (
        <Box key={itemKey} sx={{ m: '10px 10px 10px 10px' }}>
            <Button
                aria-controls={open ? `menu-${itemKey}` : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                variant="text"
                sx={{ textTransform: "none" }}
            >   <Stack direction={"row"} spacing={1} alignItems={"center"} sx={{ width: '100%' }}>
                    <Typography data-testid="nested-menu-item" variant="subtitle1" color={"white"}>{item.name}</Typography>
                    {!open ? <ExpandMore sx={{ color: "white" }} ></ExpandMore> : <ExpandLess sx={{ color: "white" }}></ExpandLess>}
                </Stack>
            </Button>
            <Menu
                id={`menu-${itemKey}`}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{ elevation: 0, sx: { 'bgcolor': 'primary.main', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', } }}
            >
                {item.children.map((child, idx) => (
                    <MenuItem key={`${itemKey}-${idx}`} onClick={handleClose}>
                        <RenderMenuItem item={child} path={`${itemPath}`} />
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export const NestedMenu: React.FC<NestedMenuProps> = ({ items }) => {
    return (
        <React.Fragment>
            {items.map((item, index) => (
              <RenderMenuItem key={index} item={item} path={""} />
            ))}
        </React.Fragment>
    );
};
