import axios, { AxiosResponse } from "axios";
import { WrappedGISEntityResponse } from "../../Components/DepositSurvey/Components/gis.autocomplete";
import { findEndpointById } from "../Endpoints/endpoints";
import { SERVICE_ID } from "../../Constants/ui.constants";


export async function getCountries(
    query: string,
): Promise<AxiosResponse<WrappedGISEntityResponse>> {
    const endpoint = findEndpointById("countries")!;
    var url = new URL(endpoint);
    url.searchParams.append('query', query)
    return await axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    })
}



export async function getState(
    query: string,
    cID: string
): Promise<AxiosResponse<WrappedGISEntityResponse>> {
    const endpoint = findEndpointById("states")!;
    var url = new URL(endpoint);
    url.searchParams.append('query', query)
    url.searchParams.append('country', cID)
    return await axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    })
}


export async function getCity(
    query: string,
    cID: string,
    sID: string
): Promise<AxiosResponse<WrappedGISEntityResponse>> {
    const endpoint = findEndpointById("cities")!;
    var url = new URL(endpoint);
    url.searchParams.append('query', query)
    url.searchParams.append('country', cID)
    url.searchParams.append('state', sID)
    return await axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    })
}



