export type Sampling = {
    size:number,
    procedure: string,
    coverage:string,
    collectionMode: string[],
    partial:boolean,
    addInfo:string
}

export type ResponseRate = {
    percentage:number,
    definition:string,
    collectionMode: string[],
    partial:boolean
}

export type SamplingError = {
    estimate:number,
    confLevel:number,
    notes:string
}

export type AddInfo={
    isEmbargo:boolean,
    embargoDate:Date|null,
    universe:string,
    samplingError:SamplingError
    modes: string[],
    sampling:Sampling[],
    responseRate: ResponseRate[],
    weights:string,
    citations:string[],
    description:string,
}

export const newSampling:Sampling = {
    size: 0,
    procedure: "",
    coverage: "",
    collectionMode: [],
    partial: false,
    addInfo: ""
}

export const newResponseRate:ResponseRate = {
    percentage: 0,
    definition: "",
    collectionMode: [],
    partial: false,
}

export const nonEmptySampling = (s:Sampling) =>
  s.size !==0
  || s.procedure !== ""
  || s.coverage !== ""
  || s.collectionMode.length > 0
  || s.partial
  || s.addInfo !== ""

export const nonEmptyResponseRate = (r:ResponseRate) =>
  r.percentage !== 0
  || r.definition !== ""
  || r.collectionMode.length > 0
  || r.partial