import { Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect } from "react";

export type Error = {
    fieldName: string,
    message: string
}

export type DateRange = {
    start: Date|null,
    end: Date|null
}

interface DateRangePickerProps {
    value: DateRange,
    setValue: React.Dispatch<React.SetStateAction<DateRange>>,
    errors: Error[],
    setErrors: React.Dispatch<React.SetStateAction<Error[]>>
}

export const DateRangePicker = ({ value, setValue, errors, setErrors }: DateRangePickerProps) => {
    useEffect(() => {
        const minStartDate = new Date(2020, 8, 1);
        if (value && value.start && value.end) {
            let errs: Error[] = []

            const today = new Date()
            if(value.start < minStartDate) errs.push({fieldName:"start", message:"Start date must be after 08/01/2020"})
            if (value.start > value.end) errs.push({ fieldName: "start", message: "Start date cannot be after end date" })
            if (value.start > today) errs.push({ fieldName: "start", message: "Start date may not be in the future" })
            if (value.end > today) errs.push({ fieldName: "end", message: "End date may not be in the future" })
            setErrors(errs)
        }
    }, [value, setErrors])




    return <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" sx={{ width: "100%" }} justifyContent={"space-between"} alignContent={"center"} spacing={1}>
        <DatePicker
            views={['month', 'year']}
            sx={{ width: "100%" }}
            label="Start"
            slotProps={{
                textField: {
                    fullWidth: true,
                    variant: 'outlined',
                    error: errors && errors.some(error => error.fieldName === 'start'),
                    helperText: errors && errors.find(error => error.fieldName === 'start')?.message,
                },
            }}

            value={dayjs(value.start)}
            onChange={(val: Dayjs | null) => {
                setValue((value) => { return { ...value, start: val!.toDate() } });
            }} />
        <DatePicker
            views={['month', 'year']}
            sx={{ width: "100%" }}
            label="End"
            slotProps={{
                textField: {
                    fullWidth: true,
                    variant: 'outlined',
                    error: errors && errors.some(error => error.fieldName === 'end'),
                    helperText: errors && errors.find(error => error.fieldName === 'end')?.message,
                },
            }}
            value={dayjs(value.end)}
            onChange={(val: Dayjs | null) => {
                setValue((value) => { return { ...value, end: val!.toDate() } });
            }} />


    </Stack>
</LocalizationProvider>
  
}