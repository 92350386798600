import { Stack, ToggleButton, Typography } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Controller } from "react-hook-form";
interface ToggleButtonGroupProps {
    name: string,
    header: string,
    yesText: string,
    noText: string,
    value: boolean
}

export const CustomToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
    name,
    header,
    yesText,
    value,
    noText,
}: ToggleButtonGroupProps) => {
    
    return (
        <Stack direction="column" spacing={1} marginTop={"20px"}>
            <Typography variant='body2' sx={{ fontSize: "12px" }}>{header}</Typography> {/* Smaller font size */}
            <Controller name={name} render={({field}) => <ToggleButtonGroup
              value={value ? "yes": "no"}
              exclusive
              onChange={(_, newValue) => {field.onChange(newValue==="yes") }}
              aria-label={header}
            >
                <ToggleButton value={"yes"} aria-label="yes">
                    <Typography variant='body2' sx={{ fontSize: "12px" }}>{yesText}</Typography> {/* Smaller font size */}
                </ToggleButton>

                <ToggleButton value={"no"} aria-label="no">
                    <Typography variant='body2' sx={{ fontSize: "12px" }}>{noText}</Typography> {/* Smaller font size */}
                </ToggleButton>
            </ToggleButtonGroup>}/>
        </Stack>
    )
};
