import { Box } from "@mui/material";
import { ReactNode } from "react";
import { useAppSelector } from "../../Redux/Hooks/hooks.redux";
interface PageProps{
    children: ReactNode[]
}

export const Page:React.FC<PageProps> = ({children}:PageProps) => {
    const mode = useAppSelector((state)=>state.appState.appUIState.mode);
    
    const pageStyle = {
        width: "100%",
        margin: 0,
        backgroundColor: mode === "light" ? "white" : "black",
      };
    return (
    <Box sx={pageStyle}>
        {children}
    </Box>)

}