import { CollectionDateRange } from "../detailed.info.type";
import { emptyOrg } from "../../DAO/Org";
import { AffiliatedIndividual } from "../../DAO/AffiliatedIndividual";
import { Funder } from "../../DAO/Funder";

export const newPI: AffiliatedIndividual = {
  affiliation: null,
  firstName: '',
  lastName: ''
}

export const newFunder: Funder = {affiliation: null, grantNumber: ""}

export function createNewDetailedInfo(){
    const x: CollectionDateRange[] = [{start: null, end:null}]
    return {
      leads:null,
      GISAreas:[{country: null, state:null, city:null}],
      dates: x,
      title:"",
      surveySponsors:[emptyOrg],
      surveyOrgs:[emptyOrg],
      projectLeads:[],
      grantFunded:false,
      principalInvestigators: [newPI],
      funders: [newFunder],
      displayOrg:"",
      displaySponsor:""
    }
}