import aapor_logo from "./aapor-logo.png"
import error from "./error.png"
import roper_full from "./roper_full.svg"
import roper_full_light from "./roper_full_light.svg"
import roper_logo from "./roper_logo.svg"

export const IMAGE_ASSETS = {
    "aapor": aapor_logo,
    "error": error,
    "roper_full_light":roper_full_light,
    "roper_full":roper_full,
    "roper_logo":roper_logo
};
