import { Delete } from "@mui/icons-material";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { RequiredPrompts } from "./required.prompts";
import { CollectionModes } from "../../../Client/CollectionModes/collectionModes.client";
import { mapMap, useSet } from "../../../Util/functions";
import { SamplingProcedures } from "../../../Client/SamplingProcedures/samplingProcedures.client";
import { MultiModeChooser } from "./multi.mode.chooser";
import { newSampling } from "../../../Types/SurveyData/add.info.type";
import { DDErrorMessage } from "./dderrormessage";

interface SamplingProps {
    collectionModes: CollectionModes
    samplingProcedures: SamplingProcedures
}

export const Sampling = ({ collectionModes, samplingProcedures }: SamplingProps) => {
    const {watch } = useFormContext()
    const selectedModes = useSet<string>(watch('modes'))
    const {fields, insert, remove} = useFieldArray({name: "sampling"})

    return <Stack direction="column" sx={{ width: "100%" }} spacing={1} alignItems={"flex-start"}>
        <RequiredPrompts minReq={true} req={false} header="Sampling"></RequiredPrompts>
        <Typography variant="body2" sx={{ fontSize: "12px", marginBottom: "8px" }}>
            A description of the sample design. Please first describe the total sample, choosing all modes that apply. In most cases, the coverage for the total sample will be identical to the population as described above. Then, for each mode of data collection, please indicate the sample size, whether the sampling was probability-based, and the population coverage (for example, national adult, likely voters, seniors age 65+ or Latinos). If your study includes an oversample or another relevant partial sample, please provide information about each sample individually.
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "12px", marginBottom: "8px" }}>
            Use the notes field to provide further information, such as whether a partial sample was an oversample, whether there were any eligibility requirements for inclusion in the sample, or if any quotas or additional sample selection criteria were applied. The methods used to recruit the panel or participants should be described if the sample was drawn from a pre-recruited panel or pool of respondents.
        </Typography>
        {fields.map((field, index: number) => {
            const sizeName = `sampling[${index}].size`;
            const procedureName = `sampling[${index}].procedure`;
            const coverageName = `sampling[${index}].coverage`;
            const collectionModeName = `sampling[${index}].collectionMode`;
            return (<><Box key={field.id} sx={{
                width:"100%",
                display: 'grid',
                gridAutoFlow: 'column',
                gridTemplateColumns: '100px 180px 1fr 465px 100px 1fr 30px',
                alignItems: "end",
                gap: 1
            }}>
                <Controller
                    name={sizeName}
                    defaultValue={""}
                    render={({ field }) => (
                        <TextField
                            aria-valuemin={0}
                            type="number"
                            fullWidth
                            label="Survey size"
                            placeholder="Survey size"
                            sx={{ marginTop: "10px" }} {...field}></TextField>
                    )}
                />

                <Controller
                    name={procedureName}
                    defaultValue={""}
                    render={({ field }) => (
                        <FormControl fullWidth>
                            <InputLabel>Procedure</InputLabel>
                            <Select
                                label="Procedure"
                                fullWidth
                                value={field.value}
                                onChange={(e) => field.onChange(e.target.value)}
                            >
                                {mapMap(samplingProcedures, (k, v, index) => (
                                    <MenuItem key={index} value={k}>
                                        {v.ddName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                />
                <Controller
                    name={coverageName}
                    defaultValue={""}
                    render={({ field }) => (
                        <TextField
                            fullWidth
                            label="Coverage"
                            multiline
                            placeholder="Coverage"
                            sx={{ marginTop: "10px" }} {...field}></TextField>
                    )}
                />
                <MultiModeChooser
                    name={collectionModeName}
                    collectionModes={collectionModes}
                    selectedModes={selectedModes}
                    label="Collection Mode"
                />
                <Controller
                    name={`sampling[${index}].partial`}
                    defaultValue={true}
                    render={({ field }) => (
                        <FormControl fullWidth>
                            <InputLabel>Partial</InputLabel>
                            <Select
                                label="Partial"
                                value={field.value ? 'Yes' : "No"}
                                onChange={(e) => field.onChange(e.target.value === 'Yes')}
                            >
                                {['Yes', 'No'].map((mode: string, index: number) => (
                                    <MenuItem key={index} value={mode}>
                                        {mode}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    )}
                />
                <Controller
                    name={`sampling[${index}].addInfo`}
                    defaultValue={""}
                    render={({ field }) => (
                        <TextField
                            fullWidth
                            label="Additional Info"
                            multiline
                            placeholder="Addtional Info"
                            sx={{ marginTop: "10px" }} {...field}></TextField>
                    )}
                />
                <Button sx={{mb: 1.5}}
                    aria-label="remove-sampling"
                onClick={()=>{ remove(index)}}><Delete/></Button>
            </Box>
                <DDErrorMessage name={sizeName} />
                <DDErrorMessage name={procedureName} />
                <DDErrorMessage name={coverageName} />
                <DDErrorMessage name={collectionModeName} />
            </>)
        })}
        <Button 
        aria-label="add-sampling"
        onClick={() => {
            insert(fields.length, newSampling)
        }}> Add sample</Button>
    </Stack>


}