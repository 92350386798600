export const tandctext = [
    "This Data Deposit grants permission for the Roper Center to archive, preserve, and distribute the Data Collection identified below. By submitting through this portal you, acting on behalf of the Submitter, give assurance of the following:",
    "Submitter gives permission to the Roper Center for Public Opinion Research to make the Data Collection publicly available under the Roper Center for Public Opinion Research's standard terms of use.",
    [
        "Submitter gives permission to the Roper Center for Public Opinion Research:",
        "To redisseminate copies of the Data Collection in a variety of distribution formats according the standard terms of use of the Roper Center for Public Opinion Research",
        "To promote and advertise the Data Collection in any publicity (in any form)",
        "To describe, catalog, validate and document the Data Collection",
        "To store, translate, edit, copy or re-format the Data Collection in any way to ensure its future preservation, accessibility, and usability",
        "To incorporate metadata (cataloging information) or documentation in the Data Collection into public access catalogues"
    ],
    [
        "Submitter gives permission to the Roper Center to enhance, transform and/or rearrange the Data Collection, including the data, metadata, and all associated documentation, for any of the following purposes:",
        "Protect respondent confidentiality, as required by applicable laws, regulations or best practices.",
        "Improve usability"
    ],
    "In preparing this data collection for public archiving and distribution, Submitter has removed all information directly identifying the research subjects in these data, and has used due diligence in preventing information in the collection from being used to disclose the identity of research subjects.",
    "Submitter further agrees to release and hold harmless the Roper Center from any and all liability from claims arising out of any legal action concerning identification of research subjects, breaches of confidentiality, or invasions of privacy by or on behalf of said subjects."
];
