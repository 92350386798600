import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { CollectionModes } from "../../../Client/CollectionModes/collectionModes.client";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import { mapItems } from "../../../Util/functions";
import { CollectionMode } from "../../../Types/DAO/CollectionMode";

interface MultiModeChooserProps {
  name: string,
  collectionModes: CollectionModes
  selectedModes: Set<string>
  label: string
}

export const MultiModeChooser: React.FC<MultiModeChooserProps> = ({
                                                          name, collectionModes, selectedModes
                                                        }) => {
  const options = useMemo(() => mapItems(collectionModes).filter((mode) => selectedModes.has(mode.modeId)).map(item => {
    return {label: item.modename, id: item.modeId}
  }),[collectionModes, selectedModes])
  return <Controller
    name={name}
    defaultValue={[]}
    render={({ field }) => {
      return <FormControl fullWidth>
        <Autocomplete
          {...field}
          value={field.value.map((id: string) => collectionModes.get(id)).map((item:CollectionMode) => {
            return {label: item.modename, id: item.modeId}
          })}
          options={options}
          fullWidth multiple
          onChange={(_, data) => {field.onChange(data.map(item => item.id))}}
          renderInput={(params) => <TextField {...params} label={"Collection Mode"}/>}
        />
      </FormControl>
    }}
  />
}