import * as yup from 'yup';
import { Org } from "../../Types/DAO/Org";
import { AddInfo, ResponseRate, Sampling, SamplingError } from '../../Types/SurveyData/add.info.type';
import { AffiliatedIndividual } from "../../Types/DAO/AffiliatedIndividual";
import { Funder } from "../../Types/DAO/Funder";

const orgs = yup.mixed<Org>().defined();

//
// Defines an object that could be nullable in the system but won't validate successfully if it is
// not null
//
function requiredObject<T extends {}>(name: string,message: string) {
    return yup.mixed<T>().nullable().defined().test(name,message,
      (value) => value !== null
    )
}

// individuals matched AffiliatedIndividual
const individuals = yup.object({
    firstName: yup.string().min(1, "First Name is required").defined(),
    lastName: yup.string().min(1, "Last Name is required").defined(),
    affiliation: requiredObject<Org>("affiliation-not-null", "Affiliation is required")
})

const funder = yup.object({
    affiliation: requiredObject<Org>("affiliation-not-null", "Affiliation is required"),
    grantNumber: yup.string().defined()
}).defined();

const funders  =yup.array(funder).when('grantFunded',
  ([grantFunded], sch) => {
      return grantFunded
        ? sch.defined()
        : sch.of(yup.mixed<Funder>()).notRequired()
  }).defined()

const geography = (hasClass:string) => yup.object().shape({
    id: yup.string().required(),
    name: yup.string().required("This field is required"),
    geographicEntityClass: hasClass==="" ? yup.string().required() : yup.string().default(hasClass)
}).nullable().defined()

const principalInvestigators = yup.array().when('grantFunded',
  ([grantFunded], sch) => {
    return grantFunded
      ? sch.of(individuals).required("This field is required").min(1, "This field is required")
      : sch.of(yup.mixed<AffiliatedIndividual>()).notRequired()
}).defined()

const optionalOrgs = yup.array().of(orgs).defined();
const surveyOrgs = optionalOrgs.when(
  "displayOrg",
([displayOrg],context) => {
      return displayOrg === "" ? context.test(
        "at-least-one-org",
        "You must either choose an organization from the list or enter the name in the field below",
        o=> o.filter(x => x!==null && x.id!=='').length>0) : context
})

export const detailedInfoSchema = yup.object().shape({
    surveyOrgs,
    surveySponsors: yup.array().of(orgs).defined(),
    grantFunded: yup.boolean().defined(),
    displaySponsor:yup.string().defined(),
    displayOrg:yup.string().defined(),
    principalInvestigators,
    funders,
    title: yup.string().required("Title is required").min(3, "Title must be at least 3 characters.").defined(),
    projectLeads: yup.array().of(individuals).defined(),
    GISAreas: yup.array().of(yup.object().shape({
        country: geography("COUNTRY").test("not-null","Country is required",x=>x!==null),
        state: geography("STATE"),
        city: geography("CITY"),
    })).defined().min(1, "At least one geographic area is required"),
    dates: yup.array().of(yup.object().shape({
        start: yup.date().max(new Date(), "Start date cannot be in the future")
          .defined().nullable().test("not-null","Start date is required",d=>d!==null),
        end: yup.date().max(new Date(), "End date cannot be in the future")
            .test({
                test: function (value) {
                    const start = this.parent.start
                    if (!start) return true
                    return value && value >= start
                },
                message: "End date cannot be before start date"
            })
            .defined().nullable().test("not-null","End date is required", d=>d!=null)
    })).required()
}).required();

const invalidSamplingErrorEstimate = "Invalid sampling error estimate";
const invalidConfidenceLevel = "Invalid Confidence level";
const atLeastOneCollectionModeIsRequired = "At least one collection mode is required";
export const addInfoSchema:yup.ObjectSchema<AddInfo> = yup.object().shape({
    isEmbargo: yup.boolean().required().default(false).defined(),
    embargoDate:yup.date().when('isEmbargo', ([isEmbargo], sch) => {
        return isEmbargo ? sch.defined().required("This field is required").min(new Date(), "Embargo date cannot be in the past") : sch.notRequired()
    }).defined().nullable(),
    universe:yup.string().defined(),
    samplingError:yup.object<SamplingError>().shape({
        estimate:yup.number().defined().min(0, invalidSamplingErrorEstimate).max(100, invalidSamplingErrorEstimate),
        confLevel:yup.number().defined().min(0, invalidConfidenceLevel).max(100, invalidConfidenceLevel),
        notes:yup.string().defined()
    }).defined(),
    modes:yup.array().of(yup.string().defined()).defined(),
    sampling:yup.array().of(yup.object<Sampling>().shape({
        size:yup.number().defined().min(0,"Size cannot be negative"),
        procedure:yup.string().min(1,"Procedure is required").defined(),
        coverage:yup.string().min(1, "Coverage is required").defined(),
        collectionMode:yup.array().of(yup.string().defined()).min(1,atLeastOneCollectionModeIsRequired).defined(),
        partial:yup.boolean().defined(),
        addInfo:yup.string().defined()
    })).defined(),
    responseRate: yup.array().of(yup.object<ResponseRate>().shape({
        percentage:yup.number().min(0, "Invalid percentage").max(100, "Invalid percentage").defined(),
        definition:yup.string().min(1,"Definition is required").defined(),
        collectionMode: yup.array().min(1,atLeastOneCollectionModeIsRequired).of(yup.string().defined()).defined(),
        partial:yup.boolean().defined()
    })).defined(),
    weights:yup.string().defined(),
    citations:yup.array().of(yup.string().defined()).defined(),
    description: yup.string().defined(),
}).required()