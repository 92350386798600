import { PropsWithChildren } from "react";
import { Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export const Subtitle = ({ children, sx }: PropsWithChildren<{sx?: SxProps<Theme>}>) => {
  return  <Typography variant="body2" sx={{ fontSize: "12px", marginBottom: "8px", ...sx }}>
    {children}
  </Typography>;
}
