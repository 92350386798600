import { Dropzone, ExtFile, FileMosaic } from "@dropzone-ui/react";
import { Close } from "@mui/icons-material";
import {
    Box, Button,
    FormControl, FormHelperText,
    InputLabel,
    Link, List, ListItem, ListItemText,
    MenuItem,
    Modal,
    Select,
    Stack, TextField, Typography,
} from "@mui/material";
import React, { useEffect, useState } from 'react';
import { UI_CONSTANTS } from "../../../Constants/ui.constants";
import { modaltext_data, modaltext_doc } from "../../../Content/modal.text";
import { RequiredPrompts } from "../Components/required.prompts";
interface FileFormProps {
    setFiles: (f: File[]) => void,
    moveUp: (n: number) => void,
    setConfRisk: (r:"Y"|"N"|"DK")=>void,
    setRiskDescription: (d: string)=>void
}

export const FileForm: React.FC<FileFormProps> = ({ setFiles, moveUp, setConfRisk, setRiskDescription }: FileFormProps) => {
    const dataText = modaltext_data.split('\n').filter(e => e !== "")
    const docText = modaltext_doc.split('\n').filter(e => e !== "")
    const [fileErrorMessage, setFileErrorMessage] = useState<string>("");
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [confRiskL, setConfRiskL] = useState<"Y"|"N"|"DK">('DK');
    const [riskDescriptionL, setRiskDescriptionL] = useState<string>('')

    const renderModal = (
        open: boolean,
        handleClose: (b: boolean) => void
    ) => {
        return (<Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                maxHeight: '400px',   // Setting a fixed height
                overflowY: 'auto',
                p: 4,
            }}>
                <Stack direction={"row"} sx={{ width: "100%", position: 'sticky' }} justifyContent={"space-between"}>
                    <Typography variant="h6" component="h2">
                        What files should I upload?
                    </Typography>
                    <Button aria-label="close" onClick={() => handleClose(false)}>
                        <Close></Close>
                    </Button>
                </Stack>

                {[dataText, docText].map((arr, index) => {
                    return <Stack direction={"column"}>
                        <Typography variant="body1" sx={{ color: UI_CONSTANTS["Carnellian"], mt: 2 }}>
                            {index === 0 ? "Data" : "Documentation"}
                        </Typography>
                        <Typography variant="body1">
                            {arr[0]}
                        </Typography>
                        <List>
                            {arr.splice(1).map(text => {
                                return <ListItem>
                                    <ListItemText>{text}</ListItemText>
                                </ListItem>
                            })}
                        </List>

                    </Stack>
                })}

            </Box>
        </Modal>)
    }

    const [lFiles, setLFiles] = useState<ExtFile[]>([]);
    useEffect(()=>{setConfRisk(confRiskL)}, [confRiskL, setConfRisk])
    useEffect(() => { setFiles(lFiles.map(lFile=>lFile.file!)) }, [lFiles, setFiles])
    useEffect(() => {setRiskDescription(riskDescriptionL)}, [riskDescriptionL, setRiskDescription])

    const onClick = ()=> {
        if(lFiles.length===0) {
            setFileErrorMessage("You must choose at least one file to upload")
        } else {
            moveUp(3);
        }
    }
    return <Box paddingLeft={"50px"} paddingRight={"50px"} marginTop="20px">
        {renderModal(modalOpen, setModalOpen)}
        <Stack direction="column" alignItems={"flex-start"} spacing={2}>
            <Typography variant="h6" gutterBottom>
                Select Files to Deposit
            </Typography>
            <Typography variant="body2">
                Please upload all data and documentation files relevant to this deposit. <Link onClick={() => setModalOpen(true)}> What files should I upload?</Link>
            </Typography>
            <Dropzone onChange={setLFiles} value={lFiles}>
                {lFiles.map((file) => (
                    <FileMosaic {...file} preview />
                ))}
            </Dropzone>
            {fileErrorMessage &&  <FormHelperText style={{marginLeft: "14px"}} error={true}>{fileErrorMessage}</FormHelperText>}
            <RequiredPrompts req={true} minReq={false} header="Is there any information in any of the uploaded data files that might put respondent confidentiality at risk?"></RequiredPrompts>
            <FormControl fullWidth>
                <InputLabel>Risk</InputLabel>
                <Select
                    label="Procedure"
                    fullWidth
                    value={confRiskL}
                    onChange={(e)=>setConfRiskL(e.target.value as "Y"|"N"|"DK")}
                >
                    {["Y", "N", "DK"].map((selection, index ) => (
                        <MenuItem key={index} value={selection}>
                            {selection === "Y" && "Yes"}
                            {selection === "N" && "No"}
                            {selection === "DK" && "Don't know"}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {confRiskL === "Y" ? <>
                <Typography variant="body2" sx={{ fontSize: "12px", marginBottom: "8px" }}>
                   Describe the nature of this risk</Typography>
                <TextField multiline
                           fullWidth
                           minRows={3}
                           placeholder="Description"
                           value={riskDescriptionL}
                           onChange={(e) => setRiskDescriptionL(e.target.value)}
                           sx={{ marginTop: "10px" }}/>
            </> : false}
            <Button aria-label="continue" onClick={onClick} variant="contained" fullWidth sx={{ marginTop: "10px" }}>Continue</Button>
        </Stack>
    </Box>
}