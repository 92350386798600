import { useEffect, useState } from "react";
import { findEndpointById } from "../Endpoints/endpoints";
import axios from "axios";
import { SamplingProcedure } from "../../Types/DAO/SamplingProcedure";
import { SERVICE_ID } from "../../Constants/ui.constants";

export type SamplingProcedures = Map<string, SamplingProcedure>

async function getSamplingProcedures(): Promise<SamplingProcedures> {
    const endpoint = findEndpointById("samplingProcedures")!;
    var url = new URL(endpoint);
    var promise = await axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    });
    const spList: SamplingProcedure[] = await promise.data.data
    const samplingProcedures = new Map<string, SamplingProcedure>()
    spList.forEach((procedure) => samplingProcedures.set(procedure.spId, procedure))
    return samplingProcedures
}

export function useSamplingProcedures(): SamplingProcedures {
    const [samplingProcedures, setSamplingProcedures] = useState<SamplingProcedures>(new Map());
    useEffect(() => {
        getSamplingProcedures().then((x) => setSamplingProcedures(x));
    }, []);
    return samplingProcedures;
}