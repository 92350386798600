import * as React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Stack } from '@mui/material';
import { useUser } from "../../Hooks/Auth/useUser";
import { User } from '../../Types/User';
import { findEndpointById } from '../../Client/Endpoints/endpoints';
import { UserFeedback } from '../../Types/UserFeedback';
import { Nullable } from 'typescript-nullable';

interface FeedbackProps {
    open: boolean;
    onClose: (value: boolean) => void;
}

const FEEDBACK_TYPE = [
    'General Comment',
    'Issue/Bug Report',
    'Feature Request',
];

async function sendFeedback(user: Nullable<User>, type: string, message: string, technical: string): Promise<void> {
    try {
        const endpoint = findEndpointById("feedback")!;
        await axios.post<UserFeedback>(endpoint, {
            name: user ? user.firstName + ' ' + user.lastName : null,
            email: user ? user.email : null,
            type: FEEDBACK_TYPE[Number(type)],
            message: message,
            technical: technical,
        }, {
            headers: {
                S: 'Data-Provider'
            }
        });
    } catch (error) {
        throw (error)
    }
}

export const Feedback: React.FC<FeedbackProps> = ({ open, onClose }) => {
    const user = useUser();
    const [type, setType] = React.useState('0');
    const [comments, setComments] = React.useState('');

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    sendFeedback(user, type, comments, '');
                    onClose(false);
                },
            }}
        >
            <DialogTitle>Send Feedback</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <DialogContentText>
                        <p>Your feedback is valuable to us as we strive to continually improve.<br />
                            Please take a moment to share your thoughts with us by providing any comments, suggestions, or bug reports in the box below.
                            Your input helps us enhance your experience and better meet your needs.
                        </p>
                        <p>We appreciate your time and contribution!</p>
                    </DialogContentText>
                    <FormControl fullWidth>
                        <InputLabel required id="type-select-label">Feedback Type</InputLabel>
                        <Select
                            id="type-select"
                            label='Feedback Type'
                            labelId='type-select-label'
                            value={type}
                            onChange={(event: SelectChangeEvent) => {
                                setType(event.target.value as string);
                            }}
                            required
                        >
                            <MenuItem value='0'>{FEEDBACK_TYPE[0]}</MenuItem>
                            <MenuItem value='1'>{FEEDBACK_TYPE[1]}</MenuItem>
                            <MenuItem value='2'>{FEEDBACK_TYPE[2]}</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="comments"
                        label="Comments"
                        type="text"
                        fullWidth
                        variant="outlined"
                        multiline
                        minRows={5}
                        value={comments}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setComments(event.target.value);
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <Button type="submit">Send</Button>
            </DialogActions>
        </Dialog>
    );
}