import { Paper, Stack, Typography } from "@mui/material";
import { IMAGE_ASSETS } from "../../Assets/img/getImg";
import { UI_CONSTANTS } from "../../Constants/ui.constants";
interface ErrorOverlayProps{
    message:string,
    isInGrid:boolean
}
export const ErrorOverlay:React.FC<ErrorOverlayProps> = ({message, isInGrid}:ErrorOverlayProps) => {
    return <Paper elevation={0} sx={{alignItems:"center", display:'flex', justifyContent:'center', height:'100%'}}>
        <Stack direction="column" spacing={2} justifyContent={"center"} alignItems={"center"}>
        <img style={{width:"200px"}} alt="error-img"  src={IMAGE_ASSETS["error"]}></img>
        <Typography variant="subtitle1" sx={{color:UI_CONSTANTS["L Dark Carnellian"]}}>{message}</Typography>
        </Stack>
     
    </Paper>
}