import axios, { AxiosResponse } from 'axios';
import { Org } from "../../Types/DAO/Org";
import { findEndpointById } from '../Endpoints/endpoints';
import { SERVICE_ID } from '../../Constants/ui.constants';

export type OrgResponse = {
    data: Org[]
}


export type WrappedOrgResponse = { data: Org }
export async function getOrgs(
    query: string,
): Promise<AxiosResponse<OrgResponse>> {
    const endpoint = findEndpointById('org')!
    var url = new URL(endpoint);
    url.searchParams.append('name', query)
    return await axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    })
}

export async function getOrg(
    id: string
): Promise<AxiosResponse<WrappedOrgResponse>> {
    const endpoint = findEndpointById('read-org-by-id')!
    var url = new URL(endpoint)
    url.searchParams.append('orgId', id)
    return await axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    })
}

export function convertOrg(o: Org) {
    return { ...o, label: o.primaryName }
}

export function sameId(a: Org, b: Org) {
    return a.id === b.id
}