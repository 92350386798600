import { AddInfo } from "../add.info.type";
export function createNewAddInfo():AddInfo{
    return {
       isEmbargo:false,
       embargoDate:null,
       universe:"",
       modes:[],
       samplingError:{
        estimate:0,
        confLevel:0,
        notes:""
       },
       sampling:[],
       responseRate:[],
       weights:"",
       citations:[],
       description:"",
    }
}

