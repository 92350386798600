import { SurveyData } from "../survey.type";
import { createNewAddInfo } from "./add.info.utils";
import { createNewDetailedInfo } from "./detailed.info.utils";

export function createNewSurvey():SurveyData{
    return {
        detailedInfo:createNewDetailedInfo(),
        addInfo:createNewAddInfo(),
        files:[],
        notes:'',
        confRisk:"DK",
        riskDescription: ""
    }
}