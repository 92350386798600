import { Delete } from "@mui/icons-material"
import { Button, Stack, TextField, Typography } from "@mui/material"
import { Controller, useFieldArray } from "react-hook-form";

import { RequiredPrompts } from "./required.prompts"

export const Publication = () => {
    const {fields, insert, remove} = useFieldArray({name: "citations"})

    return <Stack direction="column" sx={{ width: "100%" }} spacing={1} alignItems={"flex-start"}>
        <RequiredPrompts minReq={true} req={false} header="Data-related Publications"></RequiredPrompts>
        <Typography variant="body2" sx={{ fontSize: "12px", marginBottom: "8px" }}>
            Please provide any citations to publications that analyze the data or subset of the data you are depositing
        </Typography>

        {fields.map((field, index: number) => {
            return (<Stack key={field.id} direction="row" spacing={1} sx={{ width: "100%" }}>
                <Controller
                    name={`citations[${index}]`}
                    defaultValue={""}
                    render={({ field }) => (
                        <TextField
                            multiline
                            minRows={4}
                            fullWidth
                            label="Citation text"
                            placeholder="Citation text"
                            sx={{ marginTop: "10px" }} {...field}></TextField>
                    )}
                />

                <Button 
                aria-label="set-citation"
                onClick={() => {
                    remove(index)
                }}><Delete/></Button>
            </Stack>)
        })}
        <Button   
        aria-label="add-pub"
        onClick={() => {
            insert(fields.length, "")
        }}> Add publication</Button>


    </Stack>


}