import { AutoGraph, DateRange as DateRangeIcon } from "@mui/icons-material";
import { Container, Skeleton, Stack, Tab, Tabs, Typography } from "@mui/material";
import { LineChart } from '@mui/x-charts/LineChart';
import { DataGrid, GridRowsProp, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import { ReportingReponse, useReportingQuery } from "../../../Client/Reporting/reporting.client";
import { useUser } from "../../../Hooks/Auth/useUser";
import { DateRange, DateRangePicker, Error } from "../../Misc/DateRangePicker";
import { ErrorOverlay } from "../../Misc/ErrorOverlay";
import { reportingCols } from "../../Misc/Grid";
interface ReportingPageProps {
    // none!
}

export const renderErrorOverlay = () => { return <ErrorOverlay isInGrid={true} message={"No data"}></ErrorOverlay> }

export const ReportingPage: React.FC<ReportingPageProps> = () => {
    function getFirstDateOfCurrentYear() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return new Date(currentYear, 0, 1);
    }

    const [tab, setTab] = useState("table")
    const associationId = useUser()!.associationId!
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [dates, setDates] = useState<DateRange>({ start: getFirstDateOfCurrentYear(), end: new Date() })

    const { isPending, data } = useReportingQuery(dates.start, dates.end, associationId)

    useEffect(() => {
        if (data && data.data.data && data.data.data.length > 0) {
            const datad = data.data.data

            data.data.data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            setGraphData(data)
            const formattedRows = datad.map((repo: ReportingReponse) => ({
                ...repo,
                //        timePeriod: `${monthNames[new Date(repo.date).getMonth() + 1]}, ${new Date(repo.date).getFullYear()}`,
                date: new Date(repo.date),
                id: new Date(repo.date).toISOString(),
            }));
            setRows(formattedRows);
        }
    }, [data]);
    
    const [errors, setErrors] = useState<Error[]>([])
    const [graphData, setGraphData] = useState(data)

    return <Container>
        <Stack direction="column" alignItems={"flex-start"} spacing={2}>
            <Stack direction="row" alignItems={"center"} spacing={1}>
                <AutoGraph color="primary"></AutoGraph>
                <Typography variant="h6" color="primary" gutterBottom>
                    Usage Data
                </Typography>
            </Stack>
            <Typography>
                Archiving data at the Roper Center increases visibility, reuse, and citation. Search by dates below to see the number of file downloads and question or study views for your collection by Roper's user community
            </Typography>
            <Stack direction="row" alignContent={"center"} justifyContent={"space-between"} sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Filter by date range</Typography>

            </Stack>
            <DateRangePicker
                errors={errors}
                setErrors={setErrors}
                value={dates}
                setValue={setDates}></DateRangePicker>
            <Stack direction={"row"} spacing={2} alignItems={"center"} alignContent={"center"}>
                <DateRangeIcon color="primary"></DateRangeIcon>
                <Typography variant="body1" color="primary" data-testid="dates-label">{(dates.start ?? (new Date(new Date().getFullYear(), 0, 1)))?.toLocaleDateString()} - {(dates.end ?? (new Date()))?.toLocaleDateString()}</Typography>
            </Stack>

            <Tabs value={tab} onChange={(_, v) => setTab(v)} aria-label="basic tabs example">
                <Tab label="Table" value={"table"} />
                <Tab label="Graph" value="graph" />
            </Tabs>

            {isPending && <Skeleton sx={{ width: "100%", height: "100%" }}></Skeleton>}
            {tab === 'table' && data && errors.length === 0 && !isPending &&
                <div style={{ height: "400px", width: "100%" }}>
                    <DataGrid
                        sx={{
                            width: "100%", boxShadow: 2,
                            border: 2,
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            }
                        }}
                        rows={rows}
                        columns={reportingCols} slots={{
                            noRowsOverlay: renderErrorOverlay,
                            toolbar: GridToolbar
                        }}
                        slotProps={{
                            toolbar: {
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                    /></div>}
            {tab === 'graph' && graphData && errors.length === 0 && !isPending && <LineChart
                xAxis={[{ data: graphData.data.data.map(datum => new Date(datum.date)), scaleType: 'time' }]}
                series={[
                    {
                        data: graphData.data.data.map(datum => datum.downloads),
                        label: "File Downloads"
                    },
                    {
                        data: graphData.data.data.map(datum => datum.studyviews),
                        label: "Study Views"
                    },
                    {
                        data: graphData.data.data.map(datum => datum.questionviews),
                        label: "Question Views"
                    },
                ]}
                height={400}
            />}
        </Stack>
    </Container>
}