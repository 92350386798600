export const basic_info_page_content = {
    "req.field":"Indicates that field is required",
    "min.req.field":"Indicates that field is required to meet AAPOR minimal disclosure standards.",
    "survey.orgs":{
        "header":"Survey Organizations",
        "lines":[
            "The survey firm or other organization that conducts the research.",
            "If multiple organizations conducted this poll, please enter each organization on its own line by pressing the 'Add Organization' button.",
        ],  
        "placeholder":"Survey Organization(s)",
        "buttonText":"Add Organisation"
    },
    "survey.sponsors":{
        "header":"Survey Sponsor(s)",
        "lines":[
            "The sponsor is the individual, company, institution, or organization that commissions and holds ultimate responsibility for the initiation, management, and in some cases funding of a study, if different from the organization that conducts the research (Survey Organization).",
            "If multiple organizations sponsored this poll, please enter each organization on its own line by pressing the 'Add Sponsor' button."
        ],
        "buttonText":"Add Sponsor"
    },
    "grant-funded":{
        "label":"Was this a grant-funded study?",
        "yes_text":"Yes",
        "no_text":"No/Don't know"
        
    },
    "group":{
        "label":"Are you submitting a single survey or a group of surveys? ",
        "yes_text":"Group of surveys",
        "no_text":"Single surveys"
    },
    "pi":{
        "header":"Principal Investigator(s)",
        "lines":["Please list the Principal Investigators in order of importance to the study. If the Principal Investigator is an organization, complete just the affiliation field."],
        "labels":[
            "First Name",
            "Last Name",
            "Affiliation"
        ],
        "buttonText":"Add PI"
    },
    "funder":{
        "header":"Funder",
        "lines":["The organization providing the primary financial support for a grant-funded study is the funder. If the funder also collaborates on the development, management, and/or analysis of the study, the funder should also be listed as a sponsor."],
        "labels":[
            "Name of funder (if any)",
            'Grant Number (if any)'
        ],
        "buttonText":"Add funder"
    }
    
}