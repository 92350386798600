import {
  Autocomplete,
  CircularProgress,
  TextField
} from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../Redux/Hooks/hooks.redux";
import { setNetworkState } from "../../../Redux/Reducer/network.state.reducer";
import { Org } from "../../../Types/DAO/Org";
import { getOrgs, sameId } from "../../../Client/Org/org.client";
import { Controller } from "react-hook-form";

type AutocompleteNucleusProps = {
  name: string;
  header: string
};

//
// autocomplete element which can used separately from AutocompleteForm
//

export const AutocompleteNucleus: React.FC<AutocompleteNucleusProps> = ({
                                                                             name,
                                                                             header,
                                                                           }: AutocompleteNucleusProps) => {
  const dispatch = useAppDispatch()
  const [query, setQuery] = useState('')
  const [fetching, setFetching] = useState(false);

  const [autocompleteOptions, setAutocompleteOptions] = useState<Org[]>([]);
  const getOptionsDelayed = useMemo(() => debounce(
    (query: string, callback: (data: Org[]) => void) => {
      if (query.length >= 3) {
        setAutocompleteOptions([])
        setFetching(true)
        getOrgs(query).then((r) => callback(r.data.data)).catch(()=> dispatch(setNetworkState(true)));
      }
    },
    500
  ),[dispatch]);

  useEffect(() => {
    if (query !== "") {
      getOptionsDelayed(query, (options: Org[]) => {
        if (Array.isArray(options)) {
          setFetching(false)
          setAutocompleteOptions(options);
        } else {
          console.log(options)
          console.error("options is not an array");
        }
      });
    }
  }, [query, getOptionsDelayed]);

  return (
    <Controller name={name} render={({field}) => <Autocomplete
      {...field}
      fullWidth
      isOptionEqualToValue={sameId}
      options={autocompleteOptions}
      onChange={(_,v) => field.onChange(v)}
      getOptionLabel={(option) => option.primaryName}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          sx={{
            fontSize: "15px",
            "& .MuiFormLabel-root": {
              fontSize: "14px",
            },
          }}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <div>
                {fetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </div>
            ),
          }}
          label={header}
    />)} />}/>

  );
}


