import { Delete } from "@mui/icons-material";
import { Button, Stack, TextField } from "@mui/material";
import { basic_info_page_content } from "../../../Content/basic.info";
import { AutocompleteNucleus } from "./autocomplete.nucleus";
import { Controller } from "react-hook-form";
import { DDErrorMessage } from "./dderrormessage";

interface PIRowProps {
    name: string,
    index: number;
    remove: (index: number) => void;
    deleteDisabled: boolean;
}

export const
  PersonRow: React.FC<PIRowProps> = ({ name, index, remove, deleteDisabled }: PIRowProps) => {
    const firstNameName = `${name}[${index}].firstName`;
    const lastNameName = `${name}[${index}].lastName`;
    const affiliationName = `${name}[${index}].affiliation`;
    return (
          <><Stack direction={"row"} justifyContent={"space-evenly"} paddingTop={"20px"}
              paddingBottom={"20px"} spacing={"20px"}>
              <Controller name={firstNameName} render={({field}) =>
                <TextField
                  {... field}
                  sx={{
                    fontSize: '12px', '& input::placeholder': {
                      fontSize: '14px',
                    }
                  }}
                  placeholder={basic_info_page_content["pi"]["labels"][0]}/>
              }/>
            <Controller name={lastNameName} render={({field}) =>
              <TextField
                {... field}
                sx={{
                  fontSize: '12px', '& input::placeholder': {
                    fontSize: '14px',
                  }
                }}
                placeholder={basic_info_page_content["pi"]["labels"][1]}/>
            }/>
            <AutocompleteNucleus name = {affiliationName} header={basic_info_page_content["pi"]["labels"][2]} />
              <Button
              aria-label="remove-pi"
              disabled={deleteDisabled}
              onClick={() => {
                  remove(index)
              }}>
                  <Delete sx={{ fontSize: "20px" }} />
              </Button>
          </Stack>
          <DDErrorMessage name={firstNameName} />
          <DDErrorMessage name={lastNameName} />
          <DDErrorMessage name={affiliationName} />
        </>)
}