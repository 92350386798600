import axios, { AxiosResponse } from 'axios';
import { findEndpointById } from '../Endpoints/endpoints';
import { SERVICE_ID } from '../../Constants/ui.constants';

export type BoxUrlResponse = {
    boxURL: string

}

export async function getBoxUrl(uid: string): Promise<AxiosResponse<BoxUrlResponse>> {
    const endpoint = findEndpointById("boxURL")!
    var url = new URL(endpoint);
    url.searchParams.append('uid', uid)
    return await axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    })
}


