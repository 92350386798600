import "@fontsource/lato"; // Defaults to weight 400
import "@fontsource/lato/400-italic.css"; // Specify weight and style
import "@fontsource/lato/400.css"; // Specify weight
import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/400-italic.css"; // Specify weight and style
import "@fontsource/montserrat/400.css"; // Specify weight

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from './App';
import { persistor, store } from "./Redux/store";
import './index.css';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}></PersistGate>
      <App />
    </Provider>
  </React.StrictMode>
);
