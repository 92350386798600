
export type OrgAlias = {
    id:string,
    name:string
}

export type Org = {
    id:string,
    primaryName:string,
    notes:string,
    aliases:OrgAlias[]
}

export const emptyOrg = {
    id: "",
    primaryName: "",
    notes: "",
    aliases: []
}

