import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { UI_CONSTANTS } from "../../../Constants/ui.constants";
import { basic_info_page_content } from "../../../Content/basic.info";
import { FunderRow } from "./funder.row";
import { PersonRow } from "./personRow";
import { useFieldArray } from "react-hook-form";
import { newFunder, newPI } from "../../../Types/SurveyData/Utils/detailed.info.utils";
import { DDErrorMessage } from "./dderrormessage";

export const
   GrantFundedStudy: React.FC<{}> = () => {

    const { fields: fieldsPI, insert: insertPI, remove: removePI } = useFieldArray({name: "principalInvestigators"})
    const { fields, insert, remove } = useFieldArray({name:"funders"})
    return (<Box sx={{ padding: "20px", marginTop: "20px", borderLeft: `1px solid ${UI_CONSTANTS["Carnellian"]}` }}>
        <Stack direction="column">
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {basic_info_page_content['pi']['header']}
            </Typography>
            <Typography sx={{ fontSize: "12px", marginBottom: "8px" }} variant="subtitle2">
                {basic_info_page_content['pi']['lines'][0]}
            </Typography>
            <div style={{ maxHeight: "300px", overflowY: 'scroll' }}>
                {fieldsPI.map((field, index) => (
                    <PersonRow name="principalInvestigators" key={field.id} index={index} remove={removePI} deleteDisabled={fieldsPI.length<=1}/>
                ))}
            </div>

            <Button variant="outlined"
            aria-label="add-pi"
                sx={{ width: "150px" }}
                onClick={() => {
                    insertPI(fieldsPI.length, newPI)
                }}>
                {basic_info_page_content['pi']['buttonText']}
            </Button>

            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }}></Divider>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {basic_info_page_content['funder']['header']}
            </Typography>
            <Typography sx={{ fontSize: "12px", marginBottom: "8px" }} variant="subtitle2">
                {basic_info_page_content['funder']['lines'][0]}
            </Typography>
            <div style={{ maxHeight: "300px", overflowY: 'scroll' }}>
                {fields.map((field, index) => <FunderRow key={field.id} index={index} remove={remove} deleteDisabled={fields.length<=1}/>)}
            </div>
            <DDErrorMessage name={"funders"}/>
            <Button variant="outlined"
            aria-label="add-funded"
                sx={{ width: "150px" }}
                onClick={() => {
                    insert(fields.length, newFunder)
                }}>
                {basic_info_page_content['funder']['buttonText']}
            </Button>


        </Stack>

    </Box>)
}