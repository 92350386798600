import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../../State/App/app.state'
import { AuthState } from '../../State/Auth/auth.state'
import { UIMode } from '../../State/UI/UI.state'


const initialState:AppState={
    appAuthState:{
        isAuth:false,
        lastLoggedIn:null,
        user:null,
        boxURL:"",
        org:null
    },
    appUIState:{
        mode:"light",
    },
   
}
export const appStateSlice = createSlice({
    name:'appState',
    initialState:initialState,
    reducers:{
        setUIMode: (state, action: PayloadAction<UIMode>) => {
            state.appUIState.mode = action.payload
        },
        setAuthState: (state, action:PayloadAction<AuthState>) => {
            state.appAuthState = action.payload
        }
    }
})



export const { setUIMode, setAuthState } = appStateSlice.actions

export default appStateSlice.reducer;