import { Button, Stack, Typography } from "@mui/material"
 
import { RequiredPrompts } from "./required.prompts"
import { PersonRow } from "./personRow";
import { useFieldArray } from "react-hook-form";
import { newPI } from "../../../Types/SurveyData/Utils/detailed.info.utils";

export const ProjectLeadsRow: React.FC<{}> = () => {

  var {fields, insert, remove} = useFieldArray({name: "projectLeads"})

  return <Stack direction="column" sx={{ width: "100%", marginTop: 2 }} alignItems={"flex-start"}>
    <Stack direction="row" sx={{ width: "100%", alignContent: "space-between" }}>
      <RequiredPrompts req={false} minReq={false} header="Project Lead(s)"></RequiredPrompts>
    </Stack>
    <Typography variant="body2" sx={{ fontSize: "12px", marginBottom: "8px" }}>
      Please list the project lead(s) at each sponsor organization for this poll.
    </Typography>
    <Stack direction={"column"} sx={{ width: "100%" }} spacing={1}>
      <div style={{ maxHeight: "300px", overflowY: 'scroll' }}>
        {fields.map((field, index) =>
          <PersonRow key={field.id} name="projectLeads" index={index} remove={remove} deleteDisabled={false} />
        )}
      </div>
      <Button aria-label={`add-pl`} sx = {{alignSelf: "flex-start"}}  onClick={()=>{insert(fields.length, newPI)}}>
        Add Project Lead
      </Button>
    </Stack>
  </Stack>
}