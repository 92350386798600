import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Button, CircularProgress, Container, Snackbar, Stack, TextField,
    Card, CardContent,
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { IMAGE_ASSETS } from "../../Assets/img/getImg";
import { auth } from "../../Client/Auth/login.client";
import { useUIStatus } from "../../Hooks/UI/useModeStatus";
import { useAppDispatch } from "../../Redux/Hooks/hooks.redux";
import { setAuthState } from "../../Redux/Reducer/app.state.reducer";
import { setNetworkState } from '../../Redux/Reducer/network.state.reducer';
import { AuthState } from "../../State/Auth/auth.state";
import { Alert } from "../Misc/Alert";
export const LoginForm: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useAppDispatch();
    const [error, setError] = useState(false)
    const [processing, setProcessing] = useState(false)

    const [eyeOpen, setEyeOpen] = useState(false);
    const mode = useUIStatus()
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setProcessing(true)
        setError(false)
        auth(email, password).then((response: AuthState) => {
            dispatch(setAuthState(response))
        }).catch((e: AxiosError) => {
            setError(true)
            if (e.response && e.response.status && e.response.status !== 401) {
                dispatch(setNetworkState(true))
            }
        }).finally(() => setProcessing(false))
    };

    return (
        <div data-testid="login-container">
            <Container maxWidth="sm" style={{ marginTop: '64px', textAlign: "center", width: '100vw', alignItems: "center" }} >
                <img style={{ width: '300px' }} src={mode === "light" ? IMAGE_ASSETS["roper_full"] : IMAGE_ASSETS["roper_full_light"]} alt="roper-logo"></img>
                <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
                    <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
                        Invalid credentials.
                    </Alert>
                </Snackbar>
                <form style={{ marginTop: "20px" }} onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <TextField
                            label="Email"
                            type="email"
                            variant="outlined"
                            inputProps={{ 'data-testid': 'email-field' }}

                            fullWidth
                            required
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <TextField
                            inputProps={{ 'data-testid': 'password-field' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setEyeOpen(!eyeOpen)}
                                            edge="end"
                                        >
                                            {!eyeOpen ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            label="Password"
                            role='password-field'
                            type={eyeOpen ? 'text' : 'password'}
                            variant="outlined"
                            fullWidth
                            required
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        <Button
                            aria-label="login"
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={processing}

                            data-testid="submit-btn"
                        >
                            Login
                        </Button>

                    </Stack>
                    {processing && <CircularProgress sx={{ textAlign: 'center', mt: 2 }}></CircularProgress>}

                </form>
                <Card sx={{ mt: 5, backgroundColor: 'lightblue' }} elevation={2}>
                    <CardContent sx={{ fontFamily: 'sans-serif', textAlign: 'start' }}>
                        <strong>Welcome to the New Roper Center Data Provider Portal!</strong>
                        <p>We've rebuilt our portal from the ground up to enhance your experience as a valued data provider. In addition to being the best way to upload new surveys to Roper, our redesigned site now offers several exciting new features:</p>
                        <p>
                            <ul>
                                <li><strong>Track Your Submissions: </strong>Easily view the status of submissions made through the portal.</li>
                                <li><strong>Access Your Surveys: </strong>Quickly find all surveys associated with your organization in the Roper archive.</li>
                                <li><strong>Download Files: </strong>Conveniently download original and curated files for your surveys.</li>
                                <li><strong>View Usage Data: </strong>Check out how often your surveys are accessed on Roper iPoll.</li>
                            </ul>
                        </p>
                        <p>We’re committed to continuous improvement and value your feedback. If you encounter any issues or have suggestions, please let us know! Simply click the speech bubble icon at the top right of the main menu bar to share your thoughts after logging in.</p>
                        <p>Thank you for being a part of the Roper community!</p>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
}
