import { Nullable } from "typescript-nullable";
import { useAppSelector } from "../../Redux/Hooks/hooks.redux";

export function useAuthStatus(): boolean  {
    const {appAuthState} = useAppSelector((state)=>(state.appState))
    return appAuthState.isAuth
}

export function useID():Nullable<string> {
    const {appAuthState} = useAppSelector((state)=>state.appState)
    return appAuthState.isAuth ? appAuthState.user?.externalId : null
}

