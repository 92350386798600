import { useEffect, useState } from "react";
import { findEndpointById } from "../Endpoints/endpoints";
import axios from "axios";
import { CollectionMode } from "../../Types/DAO/CollectionMode";
import { SERVICE_ID } from "../../Constants/ui.constants";

export type CollectionModes = Map<string, CollectionMode>

async function getCollectionModes(): Promise<CollectionModes> {
    const endpoint = findEndpointById("collectionModes")!;
    var url = new URL(endpoint);
    var promise = await axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    });
    const modeList: CollectionMode[] = await promise.data.data
    const modes = new Map<string, CollectionMode>()
    modeList.forEach((mode) => modes.set(mode.modeId, mode))
    return modes
}

export function useCollectionModes(): CollectionModes {
    const [collectionModes, setCollectionModes] = useState<CollectionModes>(new Map<string, CollectionMode>());
    useEffect(() => {
        getCollectionModes().then((x) => setCollectionModes(x));
    }, []);
    return collectionModes;
}