import { Stack, Typography } from "@mui/material";
import { IMAGE_ASSETS } from "../../../Assets/img/getImg";

interface RequiredPromptsProps {
    req: boolean,
    minReq: boolean,
    header: string
}

export const
  RequiredPrompts: React.FC<RequiredPromptsProps> = ({ req, minReq, header }: RequiredPromptsProps) => {
    return (
        <Stack direction="row" spacing={1} alignItems={"center"}>
            <Typography variant="body1" sx={{fontWeight:"bold"}} gutterBottom>{header}</Typography> {/* Smaller font size */}
            {req && <Typography variant="subtitle2" sx={{ color: "red", fontSize: "12px" }}> * </Typography>} {/* Smaller font size */}
            {minReq && <img src={IMAGE_ASSETS["aapor"]} style={{ height: "15px", width: '15px' }} alt="AAPOR Logo" />} {/* Smaller logo */}
        </Stack>
    )
};
