import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { FormHelperText } from "@mui/material";

type DDErrorMessageProps = {
  name: string
}

//
// Looks up and displays errors for a field,  has the advantage that it has no close connection to the field so
// it will work for any field even if the field is not cooperative.  Has the disadvantge that it doesn't color the
// field element outline.  I introduced it to deal with the case of some of the rows on the "additional info"
// form where some of the fields are really small and don't have a lot of room for error messages.
//
// Note that error messages can be attached to TextFields (see the title field in detailed.info.form.tsx) and can
// also be attached to the TextField inside an Autocomplete.
//
// The choice of the <FormHelperText> here makes the error message look quite a bit like the error message you
// see in that title field with the caveat that the spacing might be a little excessive
//

export const DDErrorMessage = ({name}:DDErrorMessageProps) =>
  <ErrorMessage
    name={name}
    render={({message}) => <FormHelperText style={{marginLeft: "14px"}} error={true}>{message}</FormHelperText>}
  />
