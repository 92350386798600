export const modaltext_data = `
\nThe Roper Center prefers to receive data in the following formats:
\nQuantitative data files: SPSS, SAS or Stata
\nQualitative data files: raw ASCII text format (Plain Text Format) or Rich-Text Format (RTF)
\nVideo files: MPEG4 or JPEG2000
\nAudio files: .aif or .wav`

export const modaltext_doc =`
\nIn order for future researchers to successfully analyze in interpret your data, the Roper Center must be able to provide them with the dataset and sufficient documentation and methodological information about the study. Additional materials can provide important context for the data itself. The Roper Center prefers to receive electronic documentation in formats such as ASCII (text files), DDI XML files or Microsoft Word. The original source document is preferred, but PDF files are acceptable. Please upload as much relevant documentation as possible, which can include:
\nCodebook: Specify the unambiguous variable level details, including variable names and labels, full question text, the range of possible codes and their meanings for each variable, codes used to create derived variables, missing data information, and skip pattern instructions.
\nData collection instrument(s): An electronic version of each instrument, if applicable, including interview schedules, self-administered questionnaires, data collection forms for transcribing information from records, paper tests and scales, screening forms, and call-report forms. Please also describe the circumstances in which each was used (study populations, time periods, etc.). If an survey was fielded in multiple languages, please provide the translated instruments used.
\nOther technical documentation, including the following: data dictionaries, summary statistics (frequency distributions, means, etc.) of all variables, and interviewer and coder instructions.
\nFinal project report, project summary, or other description of the project, if available.
\nIRB approval, approved protocols, Informed Consent Statement, privacy certificate, and blank consent form(s), if applicable.
\nData roster accompanying each qualitative data file.
\nBibliography of Related Literature: an electronic file listing all publications describing or resulting from the data collection. Include the full title, full names of author(s), place of publication/publisher, journal name/volume/issue, full date, and page numbers, as applicable. Do not send literature reviews or other reference lists of publications that you may have used to help conceptualize your research. Send only lists of publications that you know are based on the data in this collection.`
