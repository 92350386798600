import { Box, Button, Stack, Typography } from "@mui/material";
import Confetti from 'react-confetti';

interface SubmissionSuccessPageProps {
    // none!
}

export const SubmissionSuccessPage: React.FC<SubmissionSuccessPageProps> = () => {
    return <Box paddingLeft={"50px"} paddingRight={"50px"} marginTop="20px">
        <Stack 
        direction="column" 
        alignItems={"center"}
        sx={{  height: "100%", width:'100%' }} 
        alignContent={"center"}
        spacing={2}>
            <Typography color="primary" variant="h6">Your study was uploaded with success!</Typography>
            <Confetti></Confetti>
            <Button variant="contained" onClick={()=>{window.location.reload()}}>Submit another study</Button>

        </Stack>

    </Box>
}