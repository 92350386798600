import { Delete } from "@mui/icons-material"
import { Button, Stack, Typography } from "@mui/material"
import { useFieldArray, useWatch } from "react-hook-form";
import { getCity, getCountries, getState } from "../../../Client/GIS/gis.client"
import { GISAutocomplete } from "./gis.autocomplete"
import { RequiredPrompts } from "./required.prompts"

interface GISFormRowProps {
    removeArea: (idx: number) => void
    index: number
    deleteDisabled: boolean
}

const GISFormRow: React.FC<GISFormRowProps> = ({removeArea, index, deleteDisabled }: GISFormRowProps) => {
  const COUNTRY = `GISAreas[${index}].country`;
  const STATE = `GISAreas[${index}].state`;
  const CITY = `GISAreas[${index}].city`;

  const country = useWatch({name: COUNTRY})
  const state = useWatch({name: STATE})
  return (
    <>
      <Stack direction="row" justifyContent="space-evenly" spacing={2} sx={{ width: "100%" }} alignItems={"flex-start"}>
        <GISAutocomplete
          cID={country ? country.id : null}
          sID={state ? state.id : null}
          name={COUNTRY}
          disabled={false}
          fetcher={getCountries}
          level="Country"
        />
        <GISAutocomplete
          name={STATE}
          cID={country ? country.id : null}
          sID={state ? state.id : null}
          disabled={!country}
          fetcher={getState}
          level="State"
        />
        <GISAutocomplete
          name={CITY}
          cID={country ? country.id : null}
          sID={state ? state.id : null}
          disabled={!state}
          fetcher={getCity}
          level="City"
        />
        <Button aria-label="remove-area" sx={{paddingTop: "15px"}} onClick={() => {
          removeArea(index);
        }} disabled={deleteDisabled}>
          <Delete />
        </Button>
      </Stack>
    </>
    );
};

export const GISForm: React.FC<{}> = () => {
    const {fields, insert, remove} = useFieldArray({name: "GISAreas"});

    return (
        <Stack direction="column" sx={{ width: "100%", marginTop: 2 }} alignItems="flex-start">
            <Stack direction="row" sx={{ width: "100%" }}>
                <RequiredPrompts req={true} minReq={true} header="Geographic Coverage Area(s)" />
            </Stack>
            <Typography variant="body2" sx={{ fontSize: "12px", marginBottom: "8px" }}>
                Geographic area(s) covered by the study (e.g., New York City, Toronto, United States, Florida)
            </Typography>
            <Stack direction="column" sx={{ width: "100%" }} alignItems={"flex-start"} spacing={2}>
                {fields.map((field, index) => (
                    <GISFormRow
                        key={field.id}
                        index={index}
                        removeArea={()=>{remove(index)}}
                        deleteDisabled={fields.length<=1}
                    />
                ))}
              <Button
                aria-label="add-area"
                onClick={()=>{insert(fields.length, {country: null, state:null, city:null})}}>
                Add Geographic Area
              </Button>
            </Stack>
        </Stack>
    );
};
