import { Delete } from "@mui/icons-material";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { Controller, useFieldArray } from "react-hook-form";
import { RequiredPrompts } from "./required.prompts";

export const DataDate: React.FC<{}> = () => {

    const {fields, insert, remove} = useFieldArray({name: "dates"});

    return (<Stack direction="column" sx={{ width: "100%", marginTop: 2 }} alignItems={"flex-start"}>
        <Stack direction={"row"} sx={{ width: "100%" }}>
            <RequiredPrompts req={true} minReq={true} header="Data Collection Date(s)"></RequiredPrompts>
        </Stack>
        <Typography variant="body2" sx={{ fontSize: "12px", marginBottom: "8px" }}>
            Date(s) the data were collected
        </Typography>
        <Divider></Divider>
        <Stack direction="column" sx={{ width: "100%" }} spacing={1}>
            {fields.map((date, index) => {
                const startName = `dates[${index}].start`;
                const endName = `dates[${index}].end`;
                return <>
                    <Stack direction={"row"} sx={{ width: "100%" }} spacing={2} key={date.id}>
                        <Controller
                          name={startName}
                          render={({ field, fieldState }) => {
                              return <DatePicker
                                {...field}
                                value={dayjs(field.value)}
                                sx={{ width: "100%" }}
                                label="Start"
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        variant: "outlined",
                                        error: !!fieldState.error,
                                        helperText: fieldState.error?.message,
                                    },
                                }}></DatePicker>;
                          }}

                        />
                        <Controller
                          name={endName}
                          render={({ field, fieldState }) => (
                            <><DatePicker
                              {...field}
                              value={dayjs(field.value)}
                              sx={{ width: "100%" }}
                              label="End"
                              slotProps={{
                                  textField: {
                                      fullWidth: true,
                                      variant: "outlined",
                                      error: !!fieldState.error,
                                      helperText: fieldState.error?.message,
                                  },
                              }} />
                              <Button aria-label="remove-data" disabled={fields.length <= 1} sx={{paddingBottom: fieldState.error ? "27px": "5px"}} onClick={() => {
                            remove(index);
                        }}>
                            <Delete />
                        </Button> </>
                          )}
                        />

                </Stack></>
            })}
            <Button aria-label="new-date" sx={{alignSelf: "start"}} onClick={() => {
                insert(fields.length, { start: null, end: null })
            }}>Add Date</Button>
        </Stack>


    </Stack>);
}