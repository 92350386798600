import { styled } from '@mui/material/styles';
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import { IMAGE_ASSETS } from "../../Assets/img/getImg";
import { useUIStatus } from "../../Hooks/UI/useModeStatus";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});

export const Home: React.FC = () => {
    const mode = useUIStatus()
    return <Box sx={{ margin: "75px auto", width: '60%' }} role='main'>
        <Stack direction={"column"} spacing={3}>
            <img style={{ width: '250px', marginBottom: 50 }} src={mode === 'light' ? IMAGE_ASSETS["roper_full"] : IMAGE_ASSETS["roper_full_light"]} alt="Roper logo"></img>
            <Typography variant="h5" component={'h1'}>Welcome to our data provider portal!</Typography>
            <Typography variant="h6" component={'p'}>Choose an option below to begin:</Typography>

            <Stack direction={"column"} spacing={2} alignItems={"center"} >
                <Grid container spacing={1} alignItems={"center"} maxWidth={'50%'}>
                    <Grid item xs>
                        <NavLink to={'/form'}>
                            <Button aria-label="new-dep" variant="contained" fullWidth>Start new submission</Button>
                        </NavLink>
                    </Grid>
                    <Grid item xs={1}>
                        <CustomWidthTooltip title={
                            <Typography sx={{ color: 'white' }}>
                                Use the New Submission button to submit data and provide details about the survey. Providing
                                this information speeds curation and ensures that naming conventions and other preferences
                                are communicated to the curators. <br />
                                Uploading surveys using the New Submission button will allow you to track the survey through
                                the curation process from within the data provider portal.
                            </Typography>} arrow placement="top">
                            <HelpOutlineIcon sx={{ color: mode === 'light' ? 'black' : 'white' }} />
                        </CustomWidthTooltip>
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems={"center"} maxWidth={'50%'}>
                    <Grid item xs>
                        <NavLink to={'/box'}>
                            <Button aria-label="upload-files" variant="outlined" fullWidth>Upload files</Button>
                        </NavLink>
                    </Grid>
                    <Grid item xs={1}>
                        <CustomWidthTooltip title={
                            <Typography sx={{ color: 'white' }}>
                                Use the Upload Files button to load data and documentation files with no additional information.
                                This option is best if you intend to submit many studies at once. Please include all essential
                                information about the survey(s) in the documentation. <br />
                                Depending on the files submitted, it may not be possible to track the curation process for these surveys in the data provider portal.
                            </Typography>} arrow placement="top">
                            <HelpOutlineIcon sx={{ color: mode === 'light' ? 'black' : 'white' }} />
                        </CustomWidthTooltip>
                    </Grid>
                </Grid>
            </Stack>
            <Typography variant="h6" component={'p'} sx={{ paddingTop: '50px' }}>Thank you for depositing data at the Roper Center. If you have any questions or need assistance, click on the speech bubble in the top right of the menu bar to contact us.</Typography>

        </Stack>

    </Box>

}