import { Delete } from "@mui/icons-material";
import { Button, Stack, TextField } from "@mui/material";
import { basic_info_page_content } from "../../../Content/basic.info";
import { AutocompleteNucleus } from "./autocomplete.nucleus";
import { Controller } from "react-hook-form";
import { DDErrorMessage } from "./dderrormessage";

interface FunderRowProps {
    remove: (idx: number) => void;
    index: number;
    deleteDisabled: boolean,
}

export const FunderRow: React.FC<FunderRowProps> = ({
    index, remove, deleteDisabled
}: FunderRowProps) => {
  const affiliationName = `funders[${index}].affiliation`;
  const grantNumberName = `funders[${index}].grantNumber`;
  return (
    <>
        <Stack
        direction={"row"}
        justifyContent={"space-evenly"}
        paddingTop={"20px"}
        paddingBottom={"20px"}
        spacing={"20px"}>
          <AutocompleteNucleus name={affiliationName} header={basic_info_page_content["funder"]["labels"][0]} />
        <Controller name={grantNumberName} render={({ field }) =>
          <TextField
            {...field}
            sx={{
              fontSize: "12px",
              "& input::placeholder": {
                fontSize: "12px",
              },
            }}
            placeholder={basic_info_page_content["funder"]["labels"][1]} />} />

        <Button aria-label="remove-funder" onClick={() => {
          remove(index);
        }} disabled={deleteDisabled}>
          <Delete sx={{ fontSize: "20px" }} />
        </Button>
      </Stack>
      <DDErrorMessage name={affiliationName}/>
      <DDErrorMessage name={grantNumberName}/>
    </>
    );
};
